<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input
          size="small"
          placeholder="法人手机号/公司名称"
          v-model.trim="listQuery.name"
          style="width: 160px;margin-right: 5px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right: 3px">
          <el-icon><Search /></el-icon><span> 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin: 11px 0px 0px 15px; width: 320px"
            class="styleForm"
            label-width="105px"
          >
            <el-form-item label="小程序手机号：">
              <el-input
                size="small"
                placeholder="小程序用户手机号"
                v-model.trim="listQuery.phoneNumber"
                style="width: 160px;margin: 0;"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="购买激活状态：">
              <el-select
                size="small"
                v-model="listQuery.activeStatus"
                style="width: 160px;margin: 0;"
                clearable
              >
                <el-option label="全部" value="2"></el-option>
                <el-option label="未激活" value="0"></el-option>
                <el-option label="已激活" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select
                size="small"
                v-model="listQuery.from"
                style="width: 160px;margin: 0;"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="报税小程序" value="taxApp"></el-option>
                <el-option label="近十五天到期" value="expireSoon"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="时间周期：">
              
            </el-form-item> -->
          </el-form>
        </search>
      </div>
      <div>
        <el-button type="primary" size="small" @click="open">授权管理</el-button>
      </div>
    </div>
    <!-- @selection-change="handleSelectionChange"
      @current-change="handleCurrentChange" -->
    <el-table
      stripe
      :data="list"
      :height="contentStyleObj"
      style="width: 100%"
      border
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <!-- <el-table-column align="center" type="selection" width="55"> </el-table-column> -->
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column
        prop="mobile_rec"
        label="法人手机号"
        align="left"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="districtName" label="公司名称" min-width="250">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ item.name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="phoneNumber"
        label="小程序用户手机号"
        align="left"
        width="125"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ item.phoneNumber }}</p>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="openId"
        label="小程序openid"
        align="left"
        width="120"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ item.openId }}</p>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="districtName" label="税局" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ $cityFilter(item.districtCode) }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="法人" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ item.manager }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="来源" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left" v-if="item.from == 'taxApp'">
              报税小程序
            </p>
            <p style="text-align: left" v-else>平台</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="购买激活状态" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left" v-if="item.appActiveStatus == '1'">
              <i class="iconfont icon-duihao"></i>已激活
            </p>
            <p style="text-align: left" v-else>
              <i class="iconfont icon-gantanhao"></i>未激活
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="付费金额"
        align="left"
        width="120"
      >
        <template template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">
              {{ item.amount }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="购买开始激活时间" width="150">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left" v-if="item.activeStartTime">
              {{ item.activeStartTime }}
            </p>
            <p style="text-align: left" v-else>--</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="购买结束激活时间" width="150">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p
              style="text-align: left"
              v-if="item.activeEndTime"
              :style="{ color: isPastDate(item.activeEndTime) }"
            >
              {{ item.activeEndTime }}
            </p>
            <p style="text-align: left" v-else>--</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column  label="操作" align="center">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <el-button link type="primary" size="small" @click="activation(item)" :disabled="!$buttonStatus('bsxcx_gmjh')">购买激活</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
        type="taxApplet"
      />
    </div>
    <el-drawer title="授权管理" v-model="visible" destroy-on-close size="60%">
      <operation ref="businessTypee" />
    </el-drawer>
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="激活"
      v-model="addShow"
      width="19%"
    >
      <el-form size="small" :model="form" :rules="rules" ref="ruleFormRef" label-position="right"   label-width="110px">
        <el-form-item label="付费金额：" prop="amount">
          <el-input v-model="form.amount" @blur="changeMoney(form.amount)" style="width: 200px;">
            <template #append>元</template>
          </el-input> 
        </el-form-item>
        <el-form-item label="购买时长：" prop="markType">
          <el-select
            v-model="form.markType"
            placeholder="请选择购买时长"
            filterable
            size="small"
            style="width: 200px;margin: 0;"
          >
            <el-option
              v-for="item in timeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="addShow = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="save"
            :loading="saveLoading"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { xcxComInfo,activeTaxApp } from "@/api/company";
import operation from "./components/operation";
export default {
  name: "taxApplet",
  components: {
    operation,
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        from: "",
        name: "",
        phoneNumber:'',
        activeStatus:'2',
      },
      list: [],
      total: 0,
      contentStyleObj: {},
      loading: false,
      visible: false,
      form:{},
      timeList:[
        {
          value: '1',
          label: "一年",
        },
        {
          value: '2',
          label: "终身",
        }
      ],
      saveLoading:false,
      addShow: false,
      sels:[],
      ids:[],
      rules:{
        amount: [
          { required: true, message: "请输入付费金额", trigger: "blur" },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的格式,可保留两位小数' }
        ],
        markType: [
          { required: true, message: "请选择购买时长", trigger: "change" },
        ],
      }
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("taxApplet")
      ? localStorage.getItem("taxApplet") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(210);
    this.getList();
  },
  methods: {
    cancel(){
      this.listQuery = {
        page: 1,
        limit: 20,
        from: "",
        name: "",
        phoneNumber:'',
        activeStatus:'2',
      }
      this.getList();
    },
    changeMoney(e){
      let num = e*1
      this.form.amount = num.toFixed(2).toString()
    },
    handleSelectionChange(e){
      // this.sels = e;
      // this.ids= sels.value.map((v) => v.id)
    },
    // handleCurrentChange(e){
    //   console.log(e);
    // },
    getList() {
      this.loading = true;
      xcxComInfo(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    open() {
      this.visible = true;
    },
    activation(val){
      this.form={
        amount:'',
        manager:null,
        comIds:[]
      }
      this.addShow = true;
      this.form.comIds = [val.comId];
    },
    save(){
      this.$refs["ruleFormRef"].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          activeTaxApp(this.form).then((res) => {
            this.saveLoading = false;
            if (res.data.msg == "success") {
              this.$message({
                type: "success",
                message: "激活成功",
              });
              this.addShow = false;
              this.getList();
            }
          });
        }
      })
    },
    isPastDate(time) {
      let today = new Date();
      let futureDate = new Date(today.getTime() + 16 * 24 * 60 * 60 * 1000);
      let year = futureDate.getFullYear();
      let month = (futureDate.getMonth() + 1).toString().padStart(2, "0"); // padStart()方法在月份和日期不足两位数时填充0
      let day = futureDate.getDate().toString().padStart(2, "0");
      let ago = year + "-" + month + "-" + day;
      return time < ago ? "red" : "black";
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
}
.iconfont {
  font-size: 13px;
  margin-right: 4px;
}
.red-font {
  color: red;
}
</style>
