<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
    <div class="top_select">
      <div>
        <qzf-search-period
          v-model:period="listQuery.period"
          @success="getList"
        ></qzf-search-period>
        <select-common
          placeholder="请选择税种"
          v-model:modelValue="listQuery.type"
          :options="options"
          class="w-100"
          @change="getList"
          v-if="activeName == '2'"
          clearable
        />
      </div>
      <div>
        <el-button
          @click="freshData"
          type="primary"
          size="small"
          icon="Refresh"
          :loading="btnLoading"
          >更新数据</el-button
        >
        <el-button @click="set" type="primary" size="small" icon="Setting"
          >设置</el-button
        >
        <el-button
          @click="daochu"
          type="primary"
          size="small"
          icon="FolderOpened"
          >导出</el-button
        >
      </div>
    </div>
    <el-tab-pane label="任务" name="1">
      <el-table
        :data="list"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        @selection-change="handleSelectionChange"
        stripe
        show-summary
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column prop="name" label="部门名称" width="150" fixed>
        </el-table-column>
        <el-table-column prop="count" label="合计数" width="60" fixed>
        </el-table-column>
        <el-table-column label="发票" align="center">
          <el-table-column label="销项" align="center">
            <el-table-column prop="invoice_out_wfq" label="未发起" />
            <el-table-column prop="invoice_out_jxz" label="进行中" />
            <el-table-column prop="invoice_out_ok" label="成功" />
            <el-table-column prop="invoice_out_no" label="失败" />
          </el-table-column>
          <el-table-column label="进项" align="center">
            <el-table-column prop="invoice_in_wfq" label="未发起" />
            <el-table-column prop="invoice_in_jxz" label="进行中" />
            <el-table-column prop="invoice_in_ok" label="成功" />
            <el-table-column prop="invoice_in_no" label="失败" />
          </el-table-column>
        </el-table-column>
        <el-table-column label="银行" align="center">
          <el-table-column prop="bank_ysq" label="已授权" />
          <el-table-column prop="bank_wfq" label="未发起" />
          <el-table-column prop="bank_jxz" label="进行中" />
          <el-table-column prop="bank_bf_ok" label="部分成功" />
          <el-table-column prop="bank_ok" label="成功" />
        </el-table-column>
        <el-table-column label="国税" align="center">
          <el-table-column prop="tax_ysb" label="应申报" />
          <el-table-column prop="tax_wfq" label="未发起" />
          <el-table-column prop="tax_jxz" label="进行中" />
          <el-table-column prop="tax_bf_ok" label="部分成功" />
          <el-table-column prop="tax_ok" label="成功" />
        </el-table-column>
        <el-table-column label="个税" align="center">
          <el-table-column prop="gs_ysb" label="应申报" />
          <el-table-column prop="gs_wfq" label="未发起" />
          <el-table-column prop="gs_jxz" label="进行中" />
          <el-table-column prop="gs_bf_ok" label="部分成功" />
          <el-table-column prop="gs_ok" label="成功" />
        </el-table-column>
        <el-table-column label="社保" align="center">
          <el-table-column prop="sb_ysb" label="应申报" />
          <el-table-column prop="sb_wfq" label="未发起" />
          <el-table-column prop="sb_jxz" label="进行中" />
          <el-table-column prop="sb_ok" label="成功" />
          <el-table-column prop="sb_no" label="失败" />
        </el-table-column>
        <el-table-column label="检查" align="center">
          <el-table-column prop="jc_wfq" label="未发起" />
          <el-table-column prop="jc_jxz" label="进行中" />
          <el-table-column prop="jc_tg" label="通过" />
          <el-table-column prop="jc_sb" label="不通过" />
          <el-table-column prop="jc_yc" label="异常" />
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="报税任务" name="2">
      <el-table
        :data="taxList"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        stripe
        show-summary
        row-key="name"
        :span-method="mergeRows"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column prop="name" label="部门名称" width="150" fixed>
        </el-table-column>
        <el-table-column prop="type" label="税种" width="100" fixed>
        </el-table-column>
        <el-table-column prop="tax_ok" label="成功" />
        <el-table-column prop="tax_no" label="失败" />
        <el-table-column prop="tax_wfq" label="未发起" />
        <el-table-column prop="tax_jxz" label="进行中" />
        <el-table-column prop="tax_wxsb" label="无需申报" />
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="设置"
    v-model="dialogFormVisible"
    width="40%"
  >
    <div class="top_select">
      <el-checkbox v-model="allStatus" @change="changeCheck"
        >一键全选</el-checkbox
      >
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :height="500"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      default-expand-all
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="label" label="部门名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="stat" label="是否取数" width="80" align="center">
        <template #default="scope">
          <el-checkbox
            v-model="scope.row.stat"
            size="small"
            :true-label="1"
            :false-label="0"
          />
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="saveSet"
          :loading="saveLoading"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { statList, statDep, statDepFetch, taxStatList, statExport, taxStatExport } from "@/api/maintain";
import { getBaseDepts } from "@/api/sys";
import { ElMessage } from "element-plus";
import { currentAccountPeriod } from "@/utils";
const { proxy } = getCurrentInstance();
const contentStyleObj = ref({});
const activeName = ref("1");
const list = ref([]);
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
  period: currentAccountPeriod(),
});
const total = ref(0);
const btnLoading = ref(false);
const dialogFormVisible = ref(false);
const tableData = ref();
const saveLoading = ref(false);
const allStatus = ref(false);
const taxList = ref([]);
const options = ref([
  {
    label: "增值税",
    value: "增值税",
  },
  {
    label: "财务报表",
    value: "财务报表",
  },
  {
    label: "个税",
    value: "个税",
  },
  {
    label: "财产行为税",
    value: "行为税",
  },
  {
    label: "消费税",
    value: "消费税",
  },
  {
    label: "垃圾处理",
    value: "垃圾处理",
  },

  {
    label: "文化事业建设税",
    value: "文化税",
  },
  {
    label: "残保金",
    value: "残保金",
  },
  {
    label: "其他收入",
    value: "其他收入",
  },
  {
    label: "水利",
    value: "水利",
  },
  {
    label: "企业所得税",
    value: "所得税",
  },

  {
    label: "经营所得",
    value: "经营所得",
  },
]);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getList();
});
const getList = () => {
  loading.value = true;
  if (activeName.value == "1") {
    statList(listQuery.value).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        list.value = res.data.data.list;
        total.value = res.data.data.total;
      }
    });
  } else {
    taxList.value = [];
    taxStatList(listQuery.value).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        taxList.value = res.data.data.list;
        total.value = res.data.data.total;
      }
    });
  }
};
const freshData = () => {
  btnLoading.value = true;
  allStatus.value = false;
  statDep({ period: listQuery.value.period }).then((res) => {
    btnLoading.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("刷新成功");
      getList();
    }
  });
};
//设置
const set = () => {
  dialogFormVisible.value = true;
  getBaseDepts({}).then((res) => {
    if (res.data.msg == "success") {
      tableData.value = res.data.data.list[0].children
        ? res.data.data.list[0].children
        : [];
    }
  });
};
//全选
const changeCheck = () => {
  if (allStatus.value) {
    updateStat(tableData.value, "stat", 1);
  } else {
    updateStat(tableData.value, "stat", 0);
  }
};
const updateStat = (item, fieldName, newValue) => {
  item.forEach((e) => {
    e[fieldName] = newValue;
    if (e.children && e.children.length > 0) {
      updateStat(e.children, fieldName, newValue);
    }
  });
};
const saveSet = () => {
  saveLoading.value = true;
  let arr = [];
  if (tableData.value.length != 0) {
    arr = flattenArray(tableData.value);
  }
  statDepFetch({ list: arr }).then((res) => {
    saveLoading.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("设置成功,请重新更新数据");
      dialogFormVisible.value = false;
    }
  });
};
const flattenArray = (list) => {
  let newArr = [];
  list.forEach((e) => {
    newArr.push(e);
    if (e.children && e.children.length > 0) {
      newArr = newArr.concat(flattenArray(e.children));
    }
  });
  return newArr;
};
const mergeRows = ({ row, column, rowIndex, columnIndex }) => {
  if (columnIndex === 0) {
    const prevRow = taxList.value[rowIndex - 1];
    if (prevRow && row.name === prevRow.name) {
      return { rowspan: 0, colspan: 1 };
    } else {
      let rowspan = 1;
      for (let i = rowIndex + 1; i < taxList.value.length; i++) {
        if (taxList.value[i].name === row.name) {
          rowspan++;
        } else {
          break;
        }
      }
      return { rowspan, colspan: 1 };
    }
  }
};
const handleClick = (tab) => {
  activeName.value = tab.props.name;
  getList();
};
// 导出
const daochu = () =>{
  saveLoading.value = true;
  if(activeName.value == '1'){//任务
    statExport(listQuery.value).then((res) => {
    saveLoading.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("导出成功");
      window.open(res.data.data.url);
    }
  });
  }else{
    taxStatExport(listQuery.value).then((res) => {
    saveLoading.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("导出成功");
      window.open(res.data.data.url);
    }
  });
  }
}

</script>
<script>
export default {
  name: "progressTask",
};
</script>
<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
