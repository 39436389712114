<template>
  <div>
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="request"
        ></el-button>
        <el-input
          size="small"
          placeholder="手机号"
          style="width: 160px"
          clearable
          v-model.trim="phone"
          @keyup.enter="request"
        ></el-input>
        <el-button size="small" type="primary" @click="request">
          <el-icon><Search /></el-icon><span> 搜索</span>
        </el-button>
      </div>
      <div></div>
      <el-button type="primary" size="small" @click="batch">批量授权</el-button>
    </div>
    <div class="table_box">
      <el-table
        stripe
        :data="list"
        style="width: 100%"
        border
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column type="index" label="序号" width="55" align="center" />
        <el-table-column prop="phoneNumber" label="小程序用户手机号" align="center">
        </el-table-column>
        <!-- <el-table-column prop="openId" label="小程序openid" align="center">
        </el-table-column> -->
        <el-table-column prop="allowStatus" label="授权状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.allowStatus == 1" style="color: #67c23a"
              >已授权</span
            >
            <span v-else style="color: #f56c6c">未授权</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="" label="操作" align="center" width="300">
          <template #default="scope">
            <el-button
              size="small"
              type="danger"
              plain
              v-show="scope.row.allowStatus == 1"
              @click="update(scope.row)"
              >取消授权</el-button
            >
            <el-button
              size="small"
              type="primary"
              plain
              v-show="scope.row.allowStatus != 1"
              @click="update(scope.row)"
              style="margin-left: 0; width: 75px"
              >授权</el-button
            >
            <el-button
              size="small"
              type="primary"
              plain
              @click="upmark(scope.row)"
              >修改备注</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="page"
          v-model:limit="limit"
          @pagination="request"
          type="taxApplet"
        />
      </div>
    </div>
    <el-dialog
      title="修改备注"
      v-model="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入要修改的备注"
        style="height: 100px"
        v-model="textarea"
      >
      </el-input>
      <div class="btn_div">
        <el-button size="small" type="primary" @click="saveremark"
          >确定修改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { authorization, accredit, xcxEditRemark } from "@/api/company";
import { mapState } from "vuex";
export default {
  name: "operation",
  data() {
    return {
      page: 1,
      limit: 20,
      list: [],
      contentStyleObj: {},
      loading: false,
      total: 0,
      phone: "",
      arr: [],
      dialogFormVisible: false,
      datalist: {},
      textarea: "",
    };
  },
  mounted() {
    this.request();
    this.contentStyleObj = this.$getHeight(210);
  },
  methods: {
    request() {
      this.loading = true;
      //localStorage.getItem('phone')
      let phone = this.phone;
      authorization({ mobile: phone, page: this.page, limit: this.limit }).then(
        (res) => {
          this.loading = false;
          //   console.log(res);
          if (res.data.errNo == 0) {
            this.list = res.data.data.list;
            this.total = res.data.data.total;
            // console.log(this.list);
          }
        }
      );
    },
    handleSelectionChange(val) {
      this.arr = val;
    },
    update(item) {
      let arr = [];
      let status = "";
      arr.push(item.openId);
      if (item.allowStatus == 1) {
        status = 0;
      } else {
        status = 1;
      }
      accredit({ openIds: arr, allowStatus: status }).then((res) => {
        console.log(res);
        if (res.data.errNo == 0) {
          this.$qzfMessage("修改成功!");
          this.request();
        } else {
          this.$qzfMessage(res.data.msg, 2);
        }
      });
    },
    upmark(item) {
      this.dialogFormVisible = true;
      this.textarea = "";
      this.datalist = item;
    },
    saveremark() {
      let list = this.datalist;
      xcxEditRemark({ openId: list.openId, remark: this.textarea }).then(
        (res) => {
          if (res.data.errNo == 0) {
            this.$qzfMessage("修改成功!");
            this.request();
            this.dialogFormVisible = false;
          } else {
            this.$qzfMessage(res.data.msg, 2);
          }
        }
      );
    },
    batch() {
      let arr = [];
      let status = 1;
      let flag=false;
      this.arr.forEach(element => {
        if (element.allowStatus==0) {
            arr.push(element.openId)
        }else if (element.allowStatus==1) {
            arr=[]
            return
        }
      });
      console.log(arr);
      if (arr.length == 0) {
        this.$qzfMessage("请选择未授权的手机号", 1);
      } else {
        accredit({ openIds: arr, allowStatus: status }).then((res) => {
        if (res.data.errNo == 0) {
          this.$qzfMessage("修改成功!");
          this.request();
        } else {
          this.$qzfMessage(res.data.msg, 2);
        }
      });
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.btn_div {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
:deep(.el-textarea__inner) {
  height: 100px !important;
}
</style>